$(document).ready(function() { 

	$('#nav li').mouseover(function() {
		$('#nav li ul').hide();
		$(this).addClass('hover');
		$('#nav li.hover ul').show();
	});
	
	$('#nav li').mouseout(function() {
		$(this).removeClass('hover');
		$('#nav li ul').hide();
	});

    $('.flexslider').flexslider({
        animation: "slide",
		controlNav: false,
		directionNav: false
    })
	
	// mobile nav submenu
	var flag = false;
	$('.navbar-offcanvas .ol-toogle-submenu').on('touchstart click', function() {
		if(!flag) {
			flag = true;
			if ($(this).hasClass('open')) {
				$(this).parent().find('ul').hide();
				$(this).removeClass('open');

			} else {
				$(this).parent().find('ul').show();
				$(this).addClass('open');
			}
		}

	})


    });